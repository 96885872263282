import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, image, keywords, title, article }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
            keywords
          }
        }
      }
    `
  )

  const { pathname } = useLocation()

  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords.length
    ? keywords
    : site.siteMetadata.keywords.toString()
  const metaImage = `${site.siteMetadata.siteUrl}${
    image || site.siteMetadata.image
  }`
  const metaTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title
  const metaUrl = `${site.siteMetadata.siteUrl}${pathname}`

  return (
    <Helmet defaultTitle={metaTitle}>
      <meta charSet="utf-8" />
      <meta http-equiv="content-language" content={lang}></meta>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={metaUrl} />
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      <meta name="image" content={metaImage} />
      {metaUrl && <meta property="og:url" content={metaUrl} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}
      {metaImage && <meta property="og:image" content={metaImage} />}
      <meta name="twitter:card" content="summary_large_image" />
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {metaDescription && (
        <meta name="twitter:description" content={metaDescription} />
      )}
      {metaImage && <meta name="twitter:image" content={metaImage} />}
    </Helmet>
  )
}

export default SEO

SEO.defaultProps = {
  lang: `hu`,
  meta: [],
  keywords: [],
  description: ``,
  image: null,
  title: ``,
  article: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
}
