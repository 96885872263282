import Opinion from "../../../../src/components/Opinion";
import DoubleImage from "../../../../src/components/doubleImage";
import FullPageImage from "../../../../src/components/FullPageImage";
import DoubleEq from "../../../../src/components/DoubleEq";
import * as React from 'react';
export default {
  Opinion,
  DoubleImage,
  FullPageImage,
  DoubleEq,
  React
};