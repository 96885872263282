import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import opinions from "../../content/opinions/opinions.json";
import { FaArrowRight } from "react-icons/fa";

const Opinion = ({ data, lang, id }) => {
  let opinion = {};
  let image = "";
  const images = data.allFile.nodes;

  opinions[lang].forEach((element) => {
    if (element.id == id) {
      opinion = element;
    }
  });

  images.forEach((im) => {
    if (im.base == opinion.image) {
      image = im.childImageSharp.fluid;
    }
  });

  return (
    <div className='opinion row'>
      <div className='col-3'>
        <Img fluid={image} />
      </div>
      <div className='col-9 opin_content'>
        <h5>
          <b>{opinion.name}</b>
        </h5>
        <span className='tag'>{opinion.tag}</span>
        <span className='opin'>"{opinion.opinion}"</span>
        {opinion.link ? (
          <span className='opinion-link'>
            <a href={opinion.link} target='_blank' rel='noreferrer'>
              {opinion.link.replace("https://", "")} <FaArrowRight />
            </a>
          </span>
        ) : null}
      </div>
    </div>
  );
};

const opinionQuery = graphql`
      query opinionImages {
        allFile(
          filter: { absolutePath: { regex: "/content/opinions/images/" } }
        ) {
          nodes {
            base
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `;


const OpinionContainer = (props) => {
  const data = useStaticQuery(opinionQuery);
  return <Opinion {...props} data={data} />;
};

export default OpinionContainer;