import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "./Seo"

const Redirect = ({ intl }) => {
  return (
    <SEO title={`${intl.formatMessage({ id: "title" })} - ${intl.formatMessage({ id: "slogen" })}`}
      description={`${intl.formatMessage({ id: "description" })}`}
    />
  )
}

export default injectIntl(Redirect)
