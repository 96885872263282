import React from "react";

const FullPageImage = ({ children }) => {
  return (
    <div className="fullpage-image">
      <div id="trigger" />
      <div className="image">
        {children}
      </div>
    </div>
  );
}

export default FullPageImage;